var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"background-container",style:({
    backgroundImage:
      'url(' + require('@/assets/images/' + _vm.background_mobile) + ')',
    height: '100vh',
    width: '100vw',
    //overflow: 'auto',
    backgroundSize: 'cover',
    aligntems: 'center' /* Centre verticalement */,
  }),attrs:{"fluid":""}},[_c('div',{staticClass:"content-wrapper",style:({
      maxWidth: '90vw',
      maxHeight: '90vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    })},[_c('div',{staticClass:"d-flex flex-row content-container",style:({ marginTop: '14vh' })},[_c('div',{staticClass:"consigne",staticStyle:{"min-width":"255px","margin-left":"-100px","margin-right":"-100px"}},[_c('div',{staticClass:"text-center",style:({
            color: _vm.WEBAPPPRO_TEXT_COLOR_2,
            fontSize: _vm.WEBAPPPRO_FONT_SIZE_3,
            fontFamily: _vm.WEBAPPPRO_FONT_FAMILY_3,
            'font-weight': 'regular',
          })},[_c('p',[_vm._v("Signez avec votre doigt à l'intérieur du cadre")])])]),_c('div',{staticClass:"d-flex justify-center",staticStyle:{"margin-bottom":"-8px"}},[_c('drawer',{ref:"drawer",style:({
            height: '70vh',
            width: '27vh',
          }),attrs:{"clear":_vm.clearCanvas},on:{"update:clear":function($event){_vm.clearCanvas=$event},"empty":function($event){_vm.canvasEmpty = $event},"end":_vm.send}})],1),_c('div',{staticClass:"d-flex flex-row align-center consigne drawer",staticStyle:{"margin-left":"-310px"}},[_c('v-btn',{staticClass:"btn",style:({
            color: _vm.WEBAPPPRO_BUTTON_TEXT_COLOR_1,
            boxShadow: _vm.WEBAPPPRO_BUTTON_SHADOW_1,
            borderRadius: _vm.WEBAPPPRO_BUTTON_BORDER_ROUND,
            'text-transform': 'none',
            padding: '25px 100px',
            fontFamily: _vm.WEBAPPPRO_FONT_FAMILY_3,
            fontSize: _vm.WEBAPPPRO_BUTTON_FONT_SIZE,
            'font-weight': 'bold',
            'line-height': '18px',
            'letter-spacing': '0em',
            'text-align': 'center',
            marginTop: '20px',
            width: '327px',
            height: '52px',
          }),attrs:{"color":_vm.WEBAPPPRO_BUTTON_COLOR_1,"disabled":_vm.canvasEmpty || _vm.uploading,"loading":_vm.uploading},on:{"click":_vm.validate}},[_vm._v("Je valide ma signature")]),_c('v-btn',{staticClass:"btn",style:({
            color: _vm.WEBAPPPRO_BUTTON_TEXT_COLOR_2,
            boxShadow: _vm.WEBAPPPRO_BUTTON_SHADOW_2,
            borderRadius: _vm.WEBAPPPRO_BUTTON_BORDER_ROUND,
            'text-transform': 'none',
            marginBottom: '-10px',
            padding: '25px 100px',
            fontFamily: _vm.WEBAPPPRO_FONT_FAMILY_3,
            fontSize: _vm.WEBAPPPRO_BUTTON_FONT_SIZE,
            'font-weight': 'bold',
            'line-height': '18px',
            'letter-spacing': '0em',
            'text-align': 'center',
            marginTop: '10px',
            width: '327px',
            height: '52px',
            marginLeft: '20px',
          }),attrs:{"color":_vm.WEBAPPPRO_BUTTON_COLOR_2,"disabled":_vm.canvasEmpty || _vm.uploading},on:{"click":function($event){return _vm.$refs.drawer.clearCanvas()}}},[_vm._v(" Je modifie ma signature ")])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }