import { render, staticRenderFns } from "./WelcomeRedoPhoto.vue?vue&type=template&id=76a704c2&scoped=true&"
import script from "./WelcomeRedoPhoto.vue?vue&type=script&lang=js&"
export * from "./WelcomeRedoPhoto.vue?vue&type=script&lang=js&"
import style0 from "./WelcomeRedoPhoto.vue?vue&type=style&index=0&id=76a704c2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76a704c2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
installComponents(component, {VBtn,VCard})
